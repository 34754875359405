export enum Scheduling {
  WhatsIncludedMowing = 'question1',
  WhoWillBeServicingMowing = 'question2',
  HowCanIBeSureMowing = 'question3',
  HowSoonCanMowing = 'question4',
  DoIHaveToBeHomeMowing = 'question5',
  WhatsIncludedLr = 'question6',
  WhoWillBeServicingLr = 'question7',
  HowCanIBeSureLr = 'question8',
  DoIHaveToBeHomeLr = 'question9',
}
