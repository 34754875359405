import { QuoteQuestions, QuoteQuestionsKeys } from '../../../enums/quoteQuestions';
import { Question, QuoteQuestionField } from '../../../types/questions';
import { isArray, isNull, isString } from 'lodash';
import { QuoteQuestionOutput } from './types';
import { getQuoteByService } from '../../../helpers/quote';
import { UseFormSetValue } from 'react-hook-form';

export const createDefaultValues = (questions?: Question[]) => {
  const defaultValues = {} as Record<QuoteQuestions | QuoteQuestionsKeys, unknown>;
  questions?.forEach((question) => {
    defaultValues[question.label] = [];
    question.fields.forEach((field) => {
      defaultValues[field.key] = field.defaultValue;

      if (question.fields.length > 1 && field.defaultValue) {
        defaultValues[question.label] = [...(defaultValues[question.label] as QuoteQuestionsKeys[]), field.key];
      }
    });
  });

  return defaultValues;
};

export const resolveFormData = (
  questions?: Question[],
  values?: Record<QuoteQuestions | QuoteQuestionsKeys, unknown>,
) => {
  const resolvedValues = {} as Partial<QuoteQuestionOutput>;
  const skipables: string[] = [];

  const filledOnes = Object.values(values || {})
    .filter(isArray)
    .flat() as QuoteQuestionsKeys[];

  // Resolve Single Question
  questions?.forEach((question) => {
    question.fields.forEach((field) => {
      if (field.isInverted) {
        resolvedValues[field.key] = !values?.[field.key];
        skipables.push(field.key);
      } else {
        resolvedValues[field.key] = values?.[field.key] as boolean;
      }
    });
  });

  // Resolve Multiple Question
  questions?.forEach((question) => {
    question.fields.forEach((field) => {
      if (skipables.includes(field.key)) return;
      resolvedValues[field.key] = false;
    });
  });

  filledOnes.forEach((key) => {
    if (skipables.includes(key)) return;
    resolvedValues[key] = true;
  });

  // Resolve Text Question
  questions?.forEach((question) => {
    question.fields.forEach((field) => {
      if (isString(field.defaultValue)) {
        resolvedValues[field.key] = values?.[field.key] as string;
      }
    });
  });

  return resolvedValues;
};

// Drop-off: Fill single question with quote data
export const fillSingleQuestion = (
  field: QuoteQuestionField,
  quote: ReturnType<typeof getQuoteByService>,
  setValue: UseFormSetValue<Record<QuoteQuestions | QuoteQuestionsKeys, unknown>>,
) => {
  if (!quote) return;

  const { key } = field;
  const value = field.isInverted ? !quote[key] : isNull(quote[key]) ? '' : quote[key];

  setValue(key, value);
};

// Drop-off: Fill multiple questions with quote data
export const fillMultipleQuestion = (
  question: Question,
  quote: ReturnType<typeof getQuoteByService>,
  setValue: UseFormSetValue<Record<QuoteQuestions | QuoteQuestionsKeys, unknown>>,
) => {
  if (!quote) return;

  const multipleValue = question.fields.reduce(
    (acc, field) => (quote[field.key] ? [...acc, field.key] : acc),
    [] as QuoteQuestionsKeys[],
  );

  setValue(question.label, multipleValue);
};
