import { QuoteQuestions, QuoteQuestionsKeys } from '../enums/quoteQuestions';
import { Question } from '../types/questions';

export const whereToActQuestion: Question = {
  label: QuoteQuestions.WhereToAct,
  fields: [
    { key: QuoteQuestionsKeys.FullYard, defaultValue: false },
    { key: QuoteQuestionsKeys.FrontYard, defaultValue: false },
    { key: QuoteQuestionsKeys.BackYard, defaultValue: false },
    { key: QuoteQuestionsKeys.LeftYard, defaultValue: false },
    { key: QuoteQuestionsKeys.RightYard, defaultValue: false },
  ],
};

export const disposeDebrisQuestion: Question = {
  label: QuoteQuestions.DisposeDebris,
  fields: [{ key: QuoteQuestionsKeys.DesiredHaulaway, defaultValue: true, isInverted: true }],
};

export const removeLeavesQuestion: Question = {
  label: QuoteQuestions.RemoveLeaves,
  fields: [{ key: QuoteQuestionsKeys.DesiredBedCleanup, defaultValue: true, isInverted: true }],
};

export const removeDebrisQuestion: Question = {
  label: QuoteQuestions.RemoveDebris,
  fields: [
    { key: QuoteQuestionsKeys.HasDryLeaves, defaultValue: false },
    { key: QuoteQuestionsKeys.HasWetLeaves, defaultValue: false },
    { key: QuoteQuestionsKeys.HasFallenLimbs, defaultValue: false },
    { key: QuoteQuestionsKeys.HasCutLimbs, defaultValue: false },
    { key: QuoteQuestionsKeys.HasRocks, defaultValue: false },
    { key: QuoteQuestionsKeys.HasMulch, defaultValue: false },
    { key: QuoteQuestionsKeys.HasPine, defaultValue: false },
    { key: QuoteQuestionsKeys.HasPetWaste, defaultValue: false },
    { key: QuoteQuestionsKeys.HasCement, defaultValue: false },
    { key: QuoteQuestionsKeys.HasAcorns, defaultValue: false },
    { key: QuoteQuestionsKeys.HasPineCones, defaultValue: false },
    { key: QuoteQuestionsKeys.HasOther, defaultValue: false },
    { key: QuoteQuestionsKeys.OtherDebris, defaultValue: '' },
  ],
};

export const additionalInstructionsQuestion: Question = {
  label: QuoteQuestions.AdditionalInstructions,
  fields: [{ key: QuoteQuestionsKeys.Details, defaultValue: '' }],
};

export const leafRemovalQuestions: Question[] = [
  whereToActQuestion,
  disposeDebrisQuestion,
  removeLeavesQuestion,
  removeDebrisQuestion,
  additionalInstructionsQuestion,
];
