import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { QuoteQuestionsStep } from '../../../types/schema';
import { createDefaultValues, fillMultipleQuestion, fillSingleQuestion, resolveFormData } from './helper';
import { UseQuoteQuestionsProps } from './types';
import { useCreateQuoteMutation, useLazyGetProspectQuery } from '../../../store/modules/prospect/api';
import { useParams } from 'react-router-dom';
import { useSchema } from '../../../hooks/useSchema';
import { getQuoteByService } from '../../../helpers/quote';

export const useQuoteQuestions = ({ currentStep }: UseQuoteQuestionsProps) => {
  const step = currentStep as QuoteQuestionsStep;
  const questions = step.options?.questions;
  const { hashId: prospectId } = useParams();
  const [getProspect, getProspectQuery] = useLazyGetProspectQuery();
  const { vertical, schema } = useSchema();
  const defaultValues = useMemo(() => createDefaultValues(questions), [questions]);

  const { control, getValues, handleSubmit, setValue } = useForm<typeof defaultValues>({
    mode: 'onSubmit',
    defaultValues,
  });
  const [createQuote, mutation] = useCreateQuoteMutation();

  useEffect(() => {
    getProspect({ id: prospectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quote = useMemo(
    () => getQuoteByService(getProspectQuery.data?.prospect?.services, schema.service),
    [getProspectQuery.data?.prospect?.services, schema.service],
  );

  useEffect(() => {
    questions?.forEach((question) => {
      fillSingleQuestion(question.fields[0], quote, setValue);
      question.fields.forEach((field) => void fillSingleQuestion(field, quote, setValue));
      fillMultipleQuestion(question, quote, setValue);
    });
  }, [questions, quote, setValue]);

  const onSubmit = handleSubmit(() => {
    const values = getValues();
    const resolvedValues = resolveFormData(questions, values);

    createQuote({
      prospectId,
      quote: {
        typename: schema.service,
        [vertical]: resolvedValues,
      },
    });
  });

  return {
    control,
    questions,
    onSubmit,
    mutation,
    query: getProspectQuery,
  };
};
