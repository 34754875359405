import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useApplyPromoCodeMutation } from '../../../store/modules/prospect/api';
import { QueryString } from '../../../enums/storage';
import { updateApp } from '../../../store/modules/app/slice';
import { updateProspect } from '../../../store/modules/prospect/slice';
import { useCookies } from 'react-cookie';
import { useSchema } from '../../../hooks/useSchema';

export const useDeal = () => {
  const PROMOCODE_COOKIE_MAX_AGE_SECONDS = 604800; // 7 days

  const { schema } = useSchema();
  const [applyPromoCodeMutation] = useApplyPromoCodeMutation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['promoCode']);

  const dealParam = searchParams.get(QueryString.PromoCode);

  useEffect(() => {
    if (schema.isPromocodeEnabled && dealParam && dealParam !== cookies.promoCode) {
      setCookie('promoCode', dealParam, { path: '/', maxAge: PROMOCODE_COOKIE_MAX_AGE_SECONDS });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealParam, schema.isPromocodeEnabled]);

  const applyPromoCode = async (prospectId: string) => {
    if (!cookies.promoCode || !schema.isPromocodeEnabled) return;

    await applyPromoCodeMutation({ prospectId, promoCode: cookies.promoCode });

    dispatch(updateApp({ promoCode: cookies.promoCode }));
    dispatch(updateProspect({ promoCode: cookies.promoCode }));
  };

  return {
    promoCode: cookies.promoCode,
    applyPromoCode,
  };
};
