import { Text } from '@lawnstarter/ls-react-common/atoms';
import { QuoteQuestions } from '../../../../../enums/quoteQuestions';
import { lsI18NService } from '../../../../../service';
import { ApiInstantQuoteLeafRemoval, Maybe } from '../../../../../types/graphql';

interface QuoteQuestionsAnswersProps {
  quote: Maybe<ApiInstantQuoteLeafRemoval> | undefined;
}

export const QuoteQuestionsAnswers = ({ quote }: QuoteQuestionsAnswersProps) => {
  const renderList = (
    items: Array<{ condition?: boolean | null; key?: string; content?: Maybe<string> }>,
    prefix: string,
  ) =>
    items
      .filter((item) => item.condition)
      .map((item, index) => (
        <li key={item.key || `${prefix}-${index}`}>
          {item.key ? lsI18NService.t(`${prefix}.${item.key}`) : item.content}
        </li>
      ));

  const renderQuestionContent = (question: QuoteQuestions) => {
    switch (question) {
      case QuoteQuestions.WhereToAct:
        return (
          <ul>
            {renderList(
              [
                { condition: quote?.fullYard, key: 'fullYard' },
                { condition: quote?.frontYard, key: 'frontYard' },
                { condition: quote?.backYard, key: 'backYard' },
                { condition: quote?.leftYard, key: 'leftYard' },
                { condition: quote?.rightYard, key: 'rightYard' },
              ],
              'question.whereToAct',
            )}
          </ul>
        );
      case QuoteQuestions.DisposeDebris:
        return (
          <ul>
            {quote?.desiredBedCleanup !== undefined && (
              <li>{lsI18NService.t(quote.desiredBedCleanup ? 'yes' : 'no')}</li>
            )}
          </ul>
        );
      case QuoteQuestions.RemoveLeaves:
        return (
          <ul>
            {quote?.desiredHaulaway !== undefined && <li>{lsI18NService.t(quote.desiredHaulaway ? 'yes' : 'no')}</li>}
          </ul>
        );
      case QuoteQuestions.RemoveDebris:
        return (
          <ul>
            {renderList(
              [
                { condition: quote?.hasDryLeaves, key: 'hasDryLeaves' },
                { condition: quote?.hasWetLeaves, key: 'hasWetLeaves' },
                { condition: quote?.hasFallenLimbs, key: 'hasFallenLimbs' },
                { condition: quote?.hasCutLimbs, key: 'hasCutLimbs' },
                { condition: quote?.hasRocks, key: 'hasRocks' },
                { condition: quote?.hasMulch, key: 'hasMulch' },
                { condition: quote?.hasPine, key: 'hasPine' },
                { condition: quote?.hasPetWaste, key: 'hasPetWaste' },
                { condition: quote?.hasCement, key: 'hasCement' },
                { condition: quote?.hasAcorns, key: 'hasAcorns' },
                { condition: quote?.hasPineCones, key: 'hasPineCones' },
                { condition: quote?.hasOther, content: quote?.otherDebris },
              ],
              'question.removeDebris',
            )}
          </ul>
        );
      case QuoteQuestions.AdditionalInstructions:
        return (
          <ul>
            <li>{quote?.details ? quote.details : lsI18NService.t('none')}</li>
          </ul>
        );
      default:
        return null;
    }
  };

  return (
    quote && (
      <div>
        {Object.values(QuoteQuestions).map((question, index) => {
          const content = renderQuestionContent(question);

          if (!content || (Array.isArray(content) && content.length === 0)) {
            return null;
          }

          return (
            <div key={`question-${index}`} className="mb-4">
              <Text variant="bodyMedium">{lsI18NService.t(`question.${question}.title`)}</Text>
              <ul className="list-disc pl-6">
                {Array.isArray(content) ? (
                  content.map((item, idx) => <li key={`content-item-${idx}`}>{item}</li>)
                ) : (
                  <li>{content}</li>
                )}
              </ul>
            </div>
          );
        })}
      </div>
    )
  );
};
