import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../..';
import { Prospect } from '../../../types/graphql';
import { Service } from '../../../enums/service';

export const initialState: Partial<Prospect> = {
  id: null,
};

const slice = createSlice({
  name: 'prospect',
  initialState,
  reducers: {
    updateProspect(state, { payload }: PayloadAction<Partial<Prospect>>) {
      return {
        ...state,
        ...payload,
      };
    },
    resetProspect: () => initialState,
  },
});

export const { updateProspect, resetProspect } = slice.actions;

export default slice.reducer;

export const selectProspect = (state: RootState): Prospect => state.prospect;

export const selectEstimatesByService = (state: RootState, targetService: Service) => {
  const { services }: Prospect = state.prospect;
  return services?.filter((service) => service.type === targetService)?.pop()?.estimates || [];
};

export const selectEstimateSelectedByService = (state: RootState, targetService: Service) => {
  const { selectedServiceEstimates }: Prospect = state.prospect;
  return selectedServiceEstimates?.find((service) => service.type === targetService);
};
