import { lsI18NService } from '../../service';
import { StyledLandscapingLeadWrapper, StyledWrapperLink } from './styles';
import { useTrackPayload } from '../../hooks/useTrackPayload';
import { Events } from '../../enums/events';
import { trackNotLookingForClicked } from '../../service/segment/trackers';
import { BinaryModalWrapper } from '../BinaryModalWrapper';
import { useLandscapingLead } from './controller';
import { getVertical } from '../../helpers/vertical';

interface ScapingLeadProps {
  className?: string;
}

export const LandscapingLead = ({ className }: ScapingLeadProps) => {
  const { landscapingUrl } = useLandscapingLead();
  const vertical = getVertical();
  const { payloadBuilder } = useTrackPayload(Events.NotLookingForClicked);

  const onImNotLooking = (e?: React.MouseEvent) => {
    e?.preventDefault();
    const payload = payloadBuilder();
    payload && trackNotLookingForClicked(payload);
    window.location.href = landscapingUrl;
  };

  return (
    <StyledLandscapingLeadWrapper className={className}>
      <BinaryModalWrapper
        icon="home-alert-outline"
        onConfirm={onImNotLooking}
        description={lsI18NService.t('landscapingLead.description')}
      >
        <StyledWrapperLink data-testid="landscapingLead-cta">
          {lsI18NService.t('landscapingLead.label', { vertical: lsI18NService.t(`${vertical}.title`) })}
        </StyledWrapperLink>
      </BinaryModalWrapper>
    </StyledLandscapingLeadWrapper>
  );
};
