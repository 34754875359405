import { Control, FieldValues } from 'react-hook-form';
import { SwitchInputForm } from '@lawnstarter/ls-react-common/molecules';
import { lsI18NService } from '../../../../service';
import { disposeDebrisQuestion } from '../../../../constants/quoteQuestions';
import { StyledQuestionWrapper, StyledLabel, StyledSwitchWrapper } from '../styles';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
}

export const DisposeDebris = ({ control }: Props) => {
  const { fields, label } = disposeDebrisQuestion;

  return (
    <StyledQuestionWrapper>
      <StyledSwitchWrapper>
        <StyledLabel>{lsI18NService.t(`question.${label}.title`)}</StyledLabel>
        <SwitchInputForm.Text
          name={fields[0].key}
          labelOff={lsI18NService.t('no')}
          labelOn={lsI18NService.t('yes')}
          control={control}
          inverted
        />
      </StyledSwitchWrapper>
    </StyledQuestionWrapper>
  );
};
