import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';

import { QuoteQuestions as QuoteQuestionsLabel } from '../../../enums/quoteQuestions';
import { StepId } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { WhereToAct } from './Questions/whereToAct';
import { DisposeDebris } from './Questions/disposeDebris';
import { RemoveLeaves } from './Questions/removeLeaves';
import { RemoveDebris } from './Questions/removeDebris';
import { AdditionalInstructions } from './Questions/additionalInstructions';
import { Step } from '../../../layout/Step';
import { StyledHeadlineWrapper } from '../AccountAndPayment/styles';
import { BackButton } from '../../../components/BackButton';
import { StepTitle } from '../../../components/StepTitle';
import { lsI18NService } from '../../../service';
import { updateApp } from '../../../store/modules/app/slice';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { StyledActionButtonWrapper, StyledActionCol, StyledCustomAction, StyledCustomFooter } from './styles';
import { useQuoteQuestions } from './controller';
import { LandscapingLead } from '../../../components/LandscapingLead';
import { To, useNavigate } from 'react-router-dom';
import { Control, FieldValues } from 'react-hook-form';

const stepId = StepId.QuoteQuestions;

export const QuoteQuestions = () => {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentStep, vertical, goToNextStep } = useStepNavigation({ stepId });
  const { control, questions, onSubmit, mutation, query } = useQuoteQuestions({ currentStep });

  useEffect(() => {
    dispatch(updateApp({ shouldShowAddress: true }));

    return () => {
      dispatch(updateApp({ shouldShowAddress: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate(goToNextStep as To);
    }
  }, [mutation.isSuccess, goToNextStep, navigate]);

  return (
    <Step>
      <Step.Body>
        <Step.Content>
          <StyledHeadlineWrapper>
            <BackButton className="hidden md:block mr-8 md:mb-4 h-fit w-fit" />
            <StepTitle stepId={stepId} vertical={vertical} />
          </StyledHeadlineWrapper>
        </Step.Content>
        <Step.Form className="pb-48">
          {query.isLoading || query.isFetching ? (
            <Spinner />
          ) : (
            <>
              {questions?.map((question, key) => {
                const props = { control: control as unknown as Control<FieldValues>, key };

                switch (question.label) {
                  case QuoteQuestionsLabel.WhereToAct:
                    return <WhereToAct {...props} />;
                  case QuoteQuestionsLabel.DisposeDebris:
                    return <DisposeDebris {...props} />;
                  case QuoteQuestionsLabel.RemoveLeaves:
                    return <RemoveLeaves {...props} />;
                  case QuoteQuestionsLabel.RemoveDebris:
                    return <RemoveDebris {...props} />;
                  case QuoteQuestionsLabel.AdditionalInstructions:
                    return <AdditionalInstructions {...props} />;
                  default:
                    return null;
                }
              })}

              <Text style={{ textAlign: 'center', color: theme.colors.outline }}>
                {lsI18NService.t('quoteQuestions.picturesLater')}
              </Text>

              <LandscapingLead className="md:mb-40" />

              <StyledCustomFooter>
                <StyledCustomAction>
                  <StyledActionCol>
                    <StyledActionButtonWrapper>
                      <Button
                        onPress={onSubmit}
                        trackID="get-my-quote-cta"
                        testID="get-my-quote-btn"
                        mode="contained"
                        disabled={mutation.isLoading || mutation.isSuccess}
                        loading={mutation.isLoading || mutation.isSuccess}
                      >
                        {lsI18NService.t('quoteQuestions.getMyQuote')}
                      </Button>
                    </StyledActionButtonWrapper>
                  </StyledActionCol>
                </StyledCustomAction>
              </StyledCustomFooter>
            </>
          )}
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
