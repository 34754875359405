import { useCSSBindTheme } from './controller';
import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';

export default function CSSBindTheme() {
  const { cssVars } = useCSSBindTheme();

  return (
    <>
      <style type="text/css">
        {`
          :root {
            ${cssVars}
          }

          @font-face {
            src: url(${iconFont});
            font-family: MaterialCommunityIcons;
            font-display: swap;
          };        
        `}
      </style>
    </>
  );
}
