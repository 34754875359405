import tw from 'tailwind-styled-components';
import { Step } from '../../../layout/Step';

export const StyledQuestionWrapper = tw.div`
  mb-6 sm:mb-8
`;

export const StyledSwitchWrapper = tw.div`
  flex justify-between
`;

export const StyledLabel = tw.div`
  text-base font-medium mr-6
`;

export const StyledCustomFooter = tw(Step.Footer)`
  md:fixed md:shadow-top
`;

export const StyledCustomAction = tw(Step.Action)`
  md:max-w-[1536px] md:flex md:place-content-end
`;

export const StyledActionCol = tw.div`
  md:w-1/2 md:py-6
`;

export const StyledActionButtonWrapper = tw.div`
  md:max-w-[330px] w-full m-auto
`;
