import { theme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { VITE_BRAND } from '../../config/env';
import { MOBILE_QUERY } from '../../constants/general';
import { METADATA } from '../../constants/metadata';
import { StepId, Vertical } from '../../enums/schema';
import { lsI18NService } from '../../service';
import { selectApp } from '../../store/modules/app/slice';
import {
  StyledHeaderWrapper,
  StyledLeftSlot,
  StyledWrapper,
  styleSubTitle as styleSubTitleCommon,
  styleTitle as styleTitleCommon,
} from './styles';

interface StepTitleProps {
  stepId: StepId;
  vertical?: Vertical | string;
  leftSlot?: () => JSX.Element;
  rightSlot?: () => JSX.Element;
  centerOnMobile?: boolean;
  title?: string;
}

export const StepTitle = ({
  stepId,
  vertical,
  leftSlot: LeftSlot,
  rightSlot: RightSlot = () => <></>,
  centerOnMobile = false,
  title,
}: StepTitleProps) => {
  const stepIdKey = _.camelCase(stepId);
  const isMobile = useMediaQuery(MOBILE_QUERY);
  const { shouldShowDetails } = useSelector(selectApp);
  const shouldRenderDetails = shouldShowDetails || !isMobile;
  const onMobile = isMobile ? { marginBottom: theme.spacing.s2 } : { marginBottom: theme.spacing.s4 };
  const onMobileCenter = centerOnMobile && isMobile ? { textAlign: 'center' } : {};
  const styleSubTitleMobile = isMobile
    ? { ...onMobileCenter, fontSize: theme.sizing.s4, lineHeight: theme.sizing.s5 }
    : { ...onMobileCenter };

  const { displayName } = METADATA[VITE_BRAND as Brand];

  return (
    <StyledWrapper>
      <StyledHeaderWrapper className={`${LeftSlot && 'grid-cols-[min-content,1fr]'}`} data-testid="step-title-wrapper">
        {LeftSlot && (
          <StyledLeftSlot>
            <LeftSlot />
          </StyledLeftSlot>
        )}
        <Text variant="headlineMedium" style={{ ...styleTitleCommon, ...onMobileCenter, ...onMobile }}>
          {title ||
            lsI18NService.t(`${stepIdKey}.title`, {
              vertical: lsI18NService.t(`${vertical}.title`),
              brand: displayName,
            })}{' '}
          <RightSlot />
        </Text>
      </StyledHeaderWrapper>
      {shouldRenderDetails && (
        <Text
          variant="headlineSmall"
          data-testid="step-desc"
          style={{ ...styleSubTitleCommon, ...styleSubTitleMobile }}
        >
          {lsI18NService.t(`${stepIdKey}.description`, { vertical })}
        </Text>
      )}
    </StyledWrapper>
  );
};
