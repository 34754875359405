import { lazy } from 'react';
import { VerticalSchema } from '../types/schema';
import { Checkout as QuestionCheckout, Scheduling as QuestionScheduling } from '../enums/questions';
import { StepId, StepType, Vertical, VerticalType } from '../enums/schema';
import { Service } from '../enums/service';
import { Policies } from '../enums/policies';

const VerticalSelection = lazy(() => import('../pages/Cart/VerticalSelection'));
const ContactInfo = lazy(() => import('../pages/Cart/ContactInfo'));
const Scheduling = lazy(() => import('../pages/Cart/Scheduling'));
const AccountAndPayment = lazy(() => import('../pages/Cart/AccountAndPayment'));
const ExtraServices = lazy(() => import('../pages/Cart/ExtraServices'));
const LawnTreatment = lazy(() => import('../pages/Cart/LawnTreatment'));
const Welcome = lazy(() => import('../pages/Cart/Welcome'));

export const schema: VerticalSchema = {
  vertical: Vertical.Mowing,
  type: VerticalType.PrePriced,
  service: Service.Mowing,
  isPromocodeEnabled: true,
  steps: [
    {
      id: StepId.VerticalSelection,
      type: StepType.Cart,
      component: VerticalSelection,
    },
    {
      id: StepId.ContactInfo,
      type: StepType.Cart,
      component: ContactInfo,
    },
    {
      id: StepId.Scheduling,
      type: StepType.Cart,
      component: Scheduling,
      options: {
        recommendedCycle: 7,
        faq: [
          QuestionScheduling.WhatsIncludedMowing,
          QuestionScheduling.WhoWillBeServicingMowing,
          QuestionScheduling.HowCanIBeSureMowing,
          QuestionScheduling.HowSoonCanMowing,
          QuestionScheduling.DoIHaveToBeHomeMowing,
        ],
      },
    },
    {
      id: StepId.AccountAndPayment,
      type: StepType.Cart,
      component: AccountAndPayment,
      options: {
        tos: [Policies.ThreeCut, Policies.LongGrass, Policies.FortyEight],
        faq: [
          QuestionCheckout.LongGrass,
          QuestionCheckout.CutMinimum,
          QuestionCheckout.INeedToCancel,
          QuestionCheckout.WillIBeCharged,
          QuestionCheckout.HowDoIPay,
          QuestionCheckout.ChargedTaxes,
        ],
      },
    },
    {
      id: StepId.ExtraServices,
      type: StepType.Cart,
      component: ExtraServices,
      services: [
        Service.BushTrimming,
        Service.TreeCare,
        Service.Cleanups,
        Service.LeafRemoval,
        Service.Landscaping,
        Service.Other,
      ],
    },
    {
      id: StepId.LawnTreatment,
      type: StepType.Cart,
      component: LawnTreatment,
    },
    {
      id: StepId.Welcome,
      type: StepType.Cart,
      component: Welcome,
    },
  ],
};
