import { Accordion, Text } from '@lawnstarter/ls-react-common/atoms';
import { List } from 'react-native-paper';
import { lsI18NService } from '../../../../../service';
import { useTrackPayload } from '../../../../../hooks/useTrackPayload';
import { Events } from '../../../../../enums/events';
import { useCallback } from 'react';
import { trackHelpArticleExpanded } from '../../../../../service/segment/trackers';
import { PhoneDetailedBadge } from '../../../../../components/PhoneDetailedBadge';
import { StyledList, StyledListItem, StyledWrapperPhone } from './styles';
import { useStepNavigation } from '../../../../../hooks/useStepNavigation';
import { StepId } from '../../../../../enums/schema';
import { AccountAndPaymentStep } from '../../../../../types/schema';

const stepId = StepId.AccountAndPayment;

export const Questions = () => {
  const { currentStep } = useStepNavigation({ stepId });

  const { payloadBuilder } = useTrackPayload(Events.HelpArticleExpanded);
  const onOpen = useCallback(
    (articleTitle: string) => {
      const payload = payloadBuilder({ articleTitle, helpContext: 'payment' });
      payload && trackHelpArticleExpanded(payload);
    },
    [payloadBuilder],
  );

  return (
    <>
      {[...(currentStep as AccountAndPaymentStep).options!.faq].map((question, i) => (
        <Accordion
          title={lsI18NService.t(`accountAndPayment.faq.${question}.title`)}
          trackID={`faq-account-and-payment-${i}`}
          testID={`faq-account-and-payment-${i}`}
          key={i}
          onOpen={() => onOpen(lsI18NService.t(`accountAndPayment.faq.${question}.title`))}
        >
          <StyledList>
            <StyledListItem>
              <List.Item
                left={() => <Text>&#x25CF;</Text>}
                titleNumberOfLines={0}
                title={lsI18NService.t(`accountAndPayment.faq.${question}.content`)}
              />
            </StyledListItem>
          </StyledList>
        </Accordion>
      ))}
      <Accordion
        onOpen={() => onOpen(lsI18NService.t(`accountAndPayment.faq.moreQuestions.title`))}
        title={lsI18NService.t(`accountAndPayment.faq.moreQuestions.title`)}
        trackID={`faq-account-and-payment-more-questions`}
        testID={`faq-account-and-payment-more-questions`}
      >
        <StyledWrapperPhone>
          <PhoneDetailedBadge
            trackID="faq-account-and-payment-phone-detailed-badge"
            testID="faq-account-and-payment-phone-detailed-badge"
          />
        </StyledWrapperPhone>
      </Accordion>
    </>
  );
};
