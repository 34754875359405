import { useMemo } from 'react';
import { Control, FieldValues, useWatch } from 'react-hook-form';

import { useAppTheme } from '@lawnstarter/ls-react-common';
import { CheckboxOptionsForm } from '@lawnstarter/ls-react-common/molecules';
import { TextArea } from '@lawnstarter/ls-react-common/atoms';

import { lsI18NService } from '../../../../service';
import { removeDebrisQuestion } from '../../../../constants/quoteQuestions';
import { QuoteQuestions, QuoteQuestionsKeys } from '../../../../enums/quoteQuestions';
import { StyledQuestionWrapper } from '../styles';
import { isArray } from 'lodash';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
}

export const RemoveDebris = ({ control }: Props) => {
  const theme = useAppTheme();
  const watch = useWatch({ control });
  const { fields, label } = removeDebrisQuestion;

  const options = fields
    .filter(({ key }) => key !== QuoteQuestionsKeys.OtherDebris)
    .map(({ key }) => ({
      label: lsI18NService.t(`question.${label}.${key}`),
      value: key,
    }));

  const shouldShowOtherDebris = useMemo(
    () =>
      isArray(control._formValues[QuoteQuestions.RemoveDebris]) &&
      control._formValues[QuoteQuestions.RemoveDebris]?.includes(QuoteQuestionsKeys.HasOther),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch],
  );

  return (
    <StyledQuestionWrapper className="border-b border-solid pb-6 sm:pb-8 border-graySecondary">
      <CheckboxOptionsForm
        control={control}
        options={options}
        title={lsI18NService.t(`question.${label}.title`)}
        name={QuoteQuestions.RemoveDebris}
        rules={{ required: true }}
      />

      {shouldShowOtherDebris && (
        <TextArea
          required={shouldShowOtherDebris}
          control={control}
          name={QuoteQuestionsKeys.OtherDebris}
          placeholder={lsI18NService.t(`question.${label}.${QuoteQuestionsKeys.OtherDebris}`)}
          containerStyle={{ marginTop: theme.spacing.s4 }}
          rules={{ required: true }}
        />
      )}
    </StyledQuestionWrapper>
  );
};
