import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Spinner, Text, Toast } from '@lawnstarter/ls-react-common/atoms';

import { StepTitle } from '../../../components/StepTitle';
import { StyledStroke } from '../../../common/styles';
import { DatePicker } from '../../../components/DatePicker';
import { StepId } from '../../../enums/schema';
import { useSchema } from '../../../hooks/useSchema';
import { Step } from '../../../layout/Step';
import { lsI18NService } from '../../../service';
import { HeaderVariants, selectApp, updateApp } from '../../../store/modules/app/slice';
import { ErrorScreen } from '../../ErrorScreen';
import { useScheduling } from './controller';
import { StyledToast, StyledFieldWrapper, styleDate } from './styles';
import { Selector } from '../../../components/Selector';
import { usePromoCode } from '../../../hooks/usePromoCode';
import { centsToDollar } from '../../../helpers/currency';
import { CompactFaq } from './components/CompactFaq';
import { Banner } from '../../../components/Banner';
import { TOAST_MS_TO_HIDE } from '../../../constants/general';
import { Promocode } from './components/Promocode';
import { Cart } from '../../../components/Cart';
import { Events } from '../../../enums/events';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { trackStartDateSubmitted } from '../../../service/segment/trackers';
import { selectProspect } from '../../../store/modules/prospect/slice';
import { Status } from '../../../enums/prospect';
import { ErrorToast } from '../../../components/ErrorToast';
import { InvalidPromoCodeError } from '../../../constants/GraphQLErrors';
import { LandscapingLead } from '../../../components/LandscapingLead';
import { QuoteQuestionsAnswers } from './components/QuoteQuestionsAnswers';
import { getQuoteByService } from '../../../helpers/quote';
import { useLazyGetProspectQuery } from '../../../store/modules/prospect/api';
import { useParams } from 'react-router-dom';
import { BackButton } from '../../../components/BackButton';

const stepId = StepId.Scheduling;

export const Scheduling = () => {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const { vertical, schema } = useSchema();
  const { hashId: prospectId } = useParams();
  const [getProspect, getProspectQuery] = useLazyGetProspectQuery();
  const { property, selectOptions, hasError, query, form, shouldDisplayStartDate, currentStep } = useScheduling();
  const prospect = useSelector(selectProspect);
  const { address1: street, city, zip } = property;
  const [showToast, setShowToast] = useState(false);
  const { openModal, promocode, title: promoTitle } = usePromoCode();
  const { payloadBuilder: startDatePayload } = useTrackPayload(Events.StartDateSubmitted);
  const { error } = useSelector(selectApp);

  useEffect(() => {
    getProspect({ id: prospectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quote = useMemo(
    () => getQuoteByService(getProspectQuery.data?.prospect?.services, schema.service),
    [getProspectQuery.data?.prospect?.services, schema.service],
  );

  useEffect(() => {
    dispatch(updateApp({ headerRightSlot: HeaderVariants.DetailedBadgePhone, shouldShowAddress: true }));

    return () => {
      dispatch(updateApp({ shouldShowAddress: false }));
    };
  }, [dispatch]);

  const onDate = (date: dayjs.Dayjs | null) => {
    if (!date) {
      return;
    }

    const payload = startDatePayload({ startDate: date.format('YYYY-MM-DD') });
    payload && trackStartDateSubmitted(payload);
  };

  return query.isLoading || prospect?.status === Status.Completed ? (
    <Spinner size={theme.sizing.s13} />
  ) : hasError ? (
    <ErrorScreen
      image={{ name: 'property', isBranded: false }}
      title={lsI18NService.t('calculationScreen.error.provideQuote')}
      label={`${street}, ${city}, ${zip}`}
    />
  ) : (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle
            title={promoTitle}
            stepId={stepId}
            vertical={vertical}
            rightSlot={() => <CompactFaq />}
            leftSlot={currentStep.options?.showBackButton ? () => <BackButton /> : undefined}
          />
          <Banner
            className="hidden md:block"
            title={lsI18NService.t('scheduling.banner.title')}
            icon="lightbulb-outline"
          >
            {getProspectQuery.isFetching || getProspectQuery.isLoading ? (
              <Spinner />
            ) : quote ? (
              <QuoteQuestionsAnswers quote={quote} />
            ) : (
              lsI18NService.t('scheduling.banner.description')
            )}
          </Banner>
        </Step.Content>
        <Step.Form className="pb-56">
          {selectOptions.map((estimate) => (
            <Selector
              key={estimate.key}
              title={estimate.label}
              caption={lsI18NService.t(`scheduling.caption`)}
              price={`$${centsToDollar(estimate.price, 0)}`}
              onClick={() => void form.setEstimate(estimate.entity)}
              isSelected={form.estimate.id === estimate.key}
              isRecommended={currentStep.options?.recommendedCycle === estimate.entity.cycle}
            />
          ))}

          {shouldDisplayStartDate && (
            <StyledFieldWrapper>
              <Text variant="bodyMedium" style={styleDate}>
                {lsI18NService.t('scheduling.dateLabel')}
              </Text>
              <DatePicker
                value={form.startDate}
                onChange={(date: dayjs.Dayjs | null) => date && form.setStartDate(date)}
                onAccept={onDate}
              />
            </StyledFieldWrapper>
          )}

          {schema.isPromocodeEnabled && <Promocode promocode={promocode} onOpen={() => openModal({ setShowToast })} />}

          <StyledStroke className="mt-8 md:hidden" />

          <Banner className="md:hidden" title={lsI18NService.t('scheduling.banner.title')} icon="lightbulb-outline">
            {quote ? <QuoteQuestionsAnswers quote={quote} /> : lsI18NService.t('scheduling.banner.description')}
          </Banner>

          <LandscapingLead />

          {showToast && (
            <StyledToast>
              <Toast
                icon="check-circle-outline"
                variant="success"
                description={lsI18NService.t('promoCode.codeApplied')}
                msToHide={TOAST_MS_TO_HIDE}
              />
            </StyledToast>
          )}

          {/* This toast is shown only when the user's autofill contains an invalid promo code */}
          {error === InvalidPromoCodeError.error && (
            <StyledToast>
              <ErrorToast description={InvalidPromoCodeError.message} msToHide={TOAST_MS_TO_HIDE} />
            </StyledToast>
          )}

          <Step.Footer>
            <Step.Action>
              <Cart promocode={promocode} price={form.estimate.amount ?? 0} />

              <Button
                onPress={form.handleSubmit}
                mode="contained"
                trackID={`${stepId}-continue`}
                loading={form.mutation.isLoading || form.mutation.isSuccess}
                disabled={!form.isValid || form.mutation.isLoading || form.mutation.isSuccess}
              >
                {lsI18NService.t('scheduling.continue')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};

export default Scheduling;
