import { Control, FieldValues } from 'react-hook-form';
import { CheckboxOptionsForm } from '@lawnstarter/ls-react-common/molecules';
import { lsI18NService } from '../../../../service';
import { whereToActQuestion } from '../../../../constants/quoteQuestions';
import { StyledQuestionWrapper } from '../styles';
import { QuoteQuestions } from '../../../../enums/quoteQuestions';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
}

export const WhereToAct = ({ control }: Props) => {
  const { fields, label } = whereToActQuestion;

  const options = fields.map(({ key }) => ({
    label: lsI18NService.t(`question.${label}.${key}`),
    value: key,
  }));

  return (
    <StyledQuestionWrapper className="border-b border-solid pb-6 sm:pb-8 border-graySecondary">
      <CheckboxOptionsForm
        control={control}
        options={options}
        title={lsI18NService.t(`question.${label}.title`)}
        name={QuoteQuestions.WhereToAct}
        rules={{ required: true }}
      />
    </StyledQuestionWrapper>
  );
};
